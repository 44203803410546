var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"changePassword",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validationForm)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Current Password","label-for":"currPass"}},[_c('validation-provider',{attrs:{"name":"Current Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"currPass","state":errors.length > 0 ? false:null,"placeholder":"*********","type":"password"},model:{value:(_vm.form.currPass),callback:function ($$v) {_vm.$set(_vm.form, "currPass", $$v)},expression:"form.currPass"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"New Password","label-for":"newPassword"}},[_c('validation-provider',{attrs:{"name":"New Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"newPassword","type":"password","state":errors.length > 0 ? false:null,"placeholder":"*********"},model:{value:(_vm.form.newPass),callback:function ($$v) {_vm.$set(_vm.form, "newPass", $$v)},expression:"form.newPass"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Confirm New Password","label-for":"cnfrmNewPass"}},[_c('validation-provider',{attrs:{"name":"New Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cnfrmNewPass","type":"password","state":errors.length > 0 ? false:null,"placeholder":"*********"},model:{value:(_vm.form.cnfrmNewPass),callback:function ($$v) {_vm.$set(_vm.form, "cnfrmNewPass", $$v)},expression:"form.cnfrmNewPass"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"success","type":"submit"}},[_vm._v(" Change Password ")]),_c('b-button',{attrs:{"variant":"secondary"},on:{"click":_vm.clearForm}},[_vm._v(" Clear ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }