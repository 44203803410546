<template>
  <b-tabs>
    <!-- left position -->
    <b-tabs
      vertical
      nav-wrapper-class="nav-vertical"
    >
      <b-tab
        active
        title="Change Password"
      >
        <b-card>
          <ChangePassword />
        </b-card>
      </b-tab>
    </b-tabs>
  </b-tabs>
</template>

<script>
import { BTabs, BTab, BCard } from 'bootstrap-vue'
import ChangePassword from '@/components/Business-Setting-Forms/Change-password.vue'

export default {
  components: {
    ChangePassword,
    BTabs,
    BTab,
    BCard,
  },
}
</script>
